<script setup lang="ts">
const route = useRoute()
const toLogin = () => {
  if (route.query.path) {
    window.location.replace("/api/auth/login?path=" + route.query.path)
  } else {
    window.location.replace("/api/auth/login")
  }
}
</script>

<template>
  <div class="w-screen h-screen flex justify-center items-center px-2">
    <div class="absolute w-screen h-screen brightness-50">
      <img class="w-screen h-screen object-center object-cover" src="~/assets/mckl.jpg">
    </div>
    <div class="z-10 md:w-1/3 bg-white rounded-lg py-6 px-8">
      <img src="~/assets/logo/klhor.svg" class="object-cover w-44 h-20">
      <div class="text-2xl font-semibold">Sign In</div>
      <div class="text-md mt-2">You must be logged in to view this site if you're not using MCKL Wi-Fi...</div>
      <button @click="toLogin()"
              class="bg-blue-600 w-full rounded text-center mt-4 text-white py-1.5 hover:bg-blue-700 px-4">
        Sign in with Microsoft Account
      </button>
    </div>
  </div>
</template>